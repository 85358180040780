<template>
  <div class="mxs-share">
    <ShareComponent
      v-if="shareData && shareInfo"
      className="mxs-share-cc"
      ref="editor"
      :data="shareData"
      :title="title"
      :theme="$root.config.theme.includes('dark') ? 'dark' : 'light'"
    />
    <div class="share-loading" v-if="loadingStatus === 0">
      <div class="ui-loading"></div>
    </div>
  </div>
</template>

<script>
import { ShareComponent } from "../../../../../components/mxs-editor/word-viewer";
import "../../../../../components/mxs-editor/style.css";
import { applyPureReactInVue } from "veaury";
import { base64ToUint8array } from "../../../../../utils/utils";

export default {
  name: "MxsShare",
  props: {
    articleId: { type: String, default: "" },
    shareId: { type: Object, default: null },
    title: { type: String, default: '' },
  },
  data() {
    return {
      shareData: null,
      shareInfo: null,
      loadingStatus: 0,
    };
  },
  watch: {
    shareId() {
      this.fetchShareInfo();
    },
    articleId() {
      this.fetchDetail();
    }
  },
  computed: {
    accessToken() {
      return localStorage.getItem("mindmap_token");
    },
  },
  components: {
    ShareComponent: applyPureReactInVue(ShareComponent),
  },
  mounted() {
    this.fetchShareInfo();
  },
  methods: {
    async fetchShareInfo() {
      if (!this.shareId) return;
      this.loadingStatus = 0;
      const res = await this.$api.share.getShareInfo(this.shareId);
      if (res.data.isSuccess) {
        this.shareInfo = res.data.data;
        this.loadingStatus = 1;
        this.fetchDetail();
      } else {
        this.loadingStatus = 2;
      }
    },
    async fetchDetail() {
      this.loadingStatus = 0;
      if (this.shareInfo && this.articleId) {
        const res = await this.$api.share.getShareDetail(
          this.shareInfo.authorId,
          this.articleId
        );
        if (res.data.code === 0) {
          this.shareData = base64ToUint8array(res.data.data);
        }
        this.loadingStatus = 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.share-loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 000;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
